import { ICorporateKyc, IUserProfile, IWallet } from "./auth.state.models";

const ACTION_SCOPE = '[Auth]';

export namespace AuthActions {
  export class GetAuthToken {
    static readonly type = `${ACTION_SCOPE} Get Token`;
    constructor(public token: string) { }
  }

  export class SetAuthToken {
    static readonly type = `${ACTION_SCOPE} Set Token`;
    constructor(public token: string) { }
  }

  export class Login {
    static readonly type = `${ACTION_SCOPE} Login`;
    constructor(public payload: { email: string; password: string }) { }
  }

  export class SetLoadingStatus {
    static readonly type = `${ACTION_SCOPE} Set Loading`;
    constructor(public payload: boolean) { }
  }

  export class GetAccountSetupStatus {
    static readonly type = `${ACTION_SCOPE} Get Account Setup Status`;
    constructor(public payload: boolean) { }
  }
  export class Signup {
    static readonly type = `${ACTION_SCOPE} Signup`;
    constructor(public payload: { userId: string; token: string }) { }
  }

  export class SetAccountID {
    static readonly type = `${ACTION_SCOPE}  Set Account ID`;
    constructor(public account_id: string) { }
  }

  export class SetActiveAccount {
    static readonly type = `${ACTION_SCOPE}  Set Active Account`;
    constructor(
      public account_id: string,
      public account_type: string,
      public tier: string,
      public name: string,
      public mango_account_number: string,
      public virtual_account_number: null,
      public virtual_account_name: null,
      public admin_user_id: null,
      public kyc_status: string,
      public wallet: IWallet[],
      public corporate_kyc: ICorporateKyc | null,
      public user_profile: IUserProfile,
      public accepted_pms_investment_agreement: boolean,
      public accepted_lcm_investment_agreement: boolean,
      public accepted_mutual_fund_agreement: boolean,
      public is_blocked: boolean,
    ) { }
  }

  export class SetAllAccounts {
    static readonly type = `${ACTION_SCOPE}  Set All Accounts`;
    constructor(public accounts: any[]) { }
  }

  export class LoadUserAccounts {
    static readonly type = `${ACTION_SCOPE} Load User Accounts`;
  }

  export class LoadUserAccountsSuccess {
    static readonly type = `${ACTION_SCOPE} Load User Accounts Success`;
    constructor(
      public activeAccount: {
        account_id: string;
        account_type: string | null;
        tier: string | null;
        name: string
        mango_account_number: string;
        virtual_account_number: null;
        virtual_account_name: null;
        admin_user_id: null;
        kyc_status: string;
        wallet: IWallet[];
        corporate_kyc: ICorporateKyc | null;
        user_profile: IUserProfile;
        accepted_pms_investment_agreement: boolean;
        accepted_lcm_investment_agreement: boolean;
        accepted_mutual_fund_agreement: boolean;
        is_blocked: boolean;
      },
      public allAccounts: Array<{
        account_id: string;
        account_type: string | null;
        tier: string | null;
        name: string;
        mango_account_number: string;
        virtual_account_number: null;
        virtual_account_name: null;
        admin_user_id: null;
        kyc_status: string;
        wallet: IWallet[];
        corporate_kyc: ICorporateKyc | null;
        user_profile: IUserProfile;
        accepted_pms_investment_agreement: boolean;
        accepted_lcm_investment_agreement: boolean;
        accepted_mutual_fund_agreement: boolean;
        is_blocked: boolean;
      }>
    ) { }
  }

  export class LoadUserAccountsFailure {
    static readonly type = `${ACTION_SCOPE} Load User Accounts Failure`;
    constructor(public error: any) { }
  }
}
